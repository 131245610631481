export const homeObjOne = {
  id: 'about',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'About',
  headLine: 'Project Manager, Design Engineer',
  description: 'Currently working as a senior Life Science consultant at an employee owned company in Stockholm, Sweden. Social and cooperative person that is highly adaptable, thanks in part to having traveled and met many different people and acted in various environments. Likes exploring new challanges, be it through studies or professionally. I like to be organized and find efficient ways to solve problems.',
  buttonLabel: '',
  ahref: false,
  target: '',
  imgStart: false,
  // eslint-disable-next-line no-undef
  img: require('../../img/svg-engineer.svg').default,
  alt: 'Engineer',
  dark: true,
  primary: true,
  darkText: false
}

export const homeObjTwo = {
  id: 'programming',
  lightBg: true,
  lightText: false,
  topLine: 'Programming',
  headLine: 'a Passion',
  description: 'A significant amount of time has been devoted to learning programming recently.',
  ahref: false,
  buttonLabel: 'Projects',
  imgStart: true,
  // eslint-disable-next-line no-undef
  img: require('../../img/svg-programmer2.svg').default,
  alt: 'Programmer',
  dark: true,
  primary: true,
  darkText: true 
}

export const homeObjThree = {
  id: 'contact',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Contact',
  headLine: 'Drop a line',
  description: 'Have I peaked your interest? Feel free to contact me.',
  buttonLabel: 'Contact',
  ahref: true,
  target: 'mailto:phitu1@duck.com',
  imgStart: false,
  // eslint-disable-next-line no-undef
  img: require('../../img/svg-email.svg').default,
  alt: 'email',
  dark: true,
  primary: true,
  darkText: false
}